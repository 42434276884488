@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'daisyui/dist/full.css';

.card {
    margin: 1rem; /* Add a 1rem margin to all sides */
  }

.hero-padding-main {
    padding-top: 80px;
    padding-bottom: 80px;
}