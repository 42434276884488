@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'daisyui/dist/full.css';

.font-figtree {
    @import url('https://fonts.googleapis.com/css2?family=Figtree&display=swap');

}

.hero-background {
    background-image: url('../public/mtech-bg.png');
    /* Other background properties */
}

/* App.css or your custom CSS file */
.hero-overlay.blur {
    backdrop-filter: blur(8px); /* Adjust the blur amount as needed */
}
